<template>
  <div>
    <digital-repayment-popup-bank
      :is-popup-bank-active.sync="isPopupBankActive"
      @select-item="fncSelectBank"
    />
    <b-card
      title="สถานะรับชำระและแก้ไขข้อมูล"
    >
      <b-row class="text-center">
        <b-col>
          รายการการโอนเงินผ่านธนาคาร
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1200 ? 5 : 12">
          <b-form-group
            label-for="bankCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ธนาคาร
              </div>
            </template>
            <b-input-group v-show="!bankCode">
              <b-form-input
                id="bankCode"
                v-model="inputSearchBank"
                placeholder="ค้นหาธนาคาร"
                trim
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupBank()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="bankCode">
              <b-form-input
                id="bankCode"
                :value="bankName"
                placeholder="ธนาคาร"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelBank"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1200 ? 5 : 12">
          <b-form-group
            label-for="events"
            label-cols-md="3"
            label-cols-sm="12"
            :label-align="$store.state.app.windowWidth >= 1200 ? 'right' : 'left'"
          >
            <template v-slot:label>
              <div style="margin-top:15px;">
                ตัวเลือก <span class="text-danger">*</span>
              </div>
            </template>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="radioChoice"
                name="testRadio"
                value="INVALID"
                style="margin-top:10px;"
              >
                ต้องแก้ไข
              </b-form-radio>
              <b-form-radio
                v-model="radioChoice"
                name="testRadio"
                value="ALL50"
                style="margin-top:10px;"
              >
                ย้อน 50 วัน
              </b-form-radio>
              <b-form-radio
                v-model="radioChoice"
                name="testRadio"
                value="ALL100"
                style="margin-top:10px;"
              >
                ย้อน 100 วัน
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1200 ? 2 : 12">
          <b-form-group
            label-for=""
            label-cols-md="1"
            label-cols-sm="12"
          >
            <b-button
              variant="primary"
              @click="fncUpdateBank()"
            >
              <feather-icon icon="SearchIcon" /> ค้นหาข้อมูล
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <digital-repayment-result
          :data-result="dataResult"
          :bank-code="bankCode"
        />
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroupAppend, BInputGroup, BFormRadio,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import DigitalRepaymentPopupBank from './digital-repayment-popup/DigitalRepaymentPopupBank.vue'
import DigitalRepaymentStoreModule from './DigitalRepaymentStoreModule'
import DigitalRepaymentResult from './DigitalRepaymentResult.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormRadio,
    DigitalRepaymentPopupBank,
    DigitalRepaymentResult,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appDigitalRepayment'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, DigitalRepaymentStoreModule)

    // ประกาศตัวแปร Textbox
    const inputSearchBank = ref(null)

    // ประกาศตัวแปร radio
    const radioChoice = ref('INVALID')

    // ประกาศตัวแปรสำหรับเก็บค่า
    const bankCode = ref(null)
    const bankName = ref(null)
    const dataResult = ref([])

    // ประกาศตัวแปร Active / Inactive
    const isPopupBankActive = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นเปิด Popup Bank */
    const fncOpenPopupBank = () => {
      // console.log('fncOpenPopupBank')
      isPopupBankActive.value = true
    }

    /* ฟังก์ชั่นเมื่อมีการคลิกเลือกรายการ */
    const fncSelectBank = data => {
      console.log('fncSelectBank', data)
      bankCode.value = data.tabkeytwo
      bankName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นยกเลิกธนาคารที่เลือก */
    const fncCancelBank = () => {
      bankCode.value = null
      bankName.value = null
    }

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlayLoadDataTable = ref(false)
    const accesstime = new Date()

    /* ฟังก์ชั่นupdate bank */
    const fncUpdateBank = () => {
      overlayLoadDataTable.value = true
      const payload = {
        action: 'LIST',
        events: radioChoice.value,
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        process_date: moment(accesstime).format('YYYY-MM-DD'),
        btf_bank_in: bankCode.value,
        rundte_in: null,
        cpnbrncod_in: null,
        lotnum_in: null,
        seqnum_in: null,
        connum_in: null,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_BANK_TRANSFER`, payload)
        .then(response => {
          dataResult.value = response.data.responseData

          overlayLoadDataTable.value = false // ปิดการแสดงสถานะ Loading
        })
    }

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    return {
      // ประกาศตัวแปร Textbox
      inputSearchBank,

      // ประกาศตัวแปร radio
      radioChoice,

      // ประกาศตัวแปรสำหรับเก็บค่า
      bankCode,
      bankName,
      dataResult,

      // ประกาศตัวแปร Active / Inactive
      isPopupBankActive,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupBank,
      fncSelectBank,
      fncCancelBank,
      fncUpdateBank,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlayLoadDataTable,

    }
  },
}
</script>

<style>

</style>
