<template>
  <b-modal
    ref="refModalView"
    centered
    hide-footer
    size="xl"
    title="สถานะรับชำระ และแก้ไขข้อมูล"
    @hidden="$emit('update:is-popup-view-active', false);$emit('update:pass-data-view', {});$emit('update:pass-data-view-edit-head', {});"
  >
    <b-table-simple
      caption-top
      bordered
      class="position-relative mb-2"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">
            พอร์ต
          </b-th>
          <b-th class="text-center">
            จำนวนรายการ
          </b-th>
          <b-th class="text-center">
            ยอดเงิน
          </b-th>
          <b-th class="text-center">
            ถูกต้อง
          </b-th>
          <b-th class="text-center">
            ผิดพลาด
          </b-th>
          <b-th class="text-center">
            แก้ไขแล้ว
          </b-th>
          <b-th class="text-center">
            ต้องแก้ไข
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="text-center">
            {{ passDataViewEditHead.port_desc }}
          </b-td>
          <b-td class="text-center">
            {{ passDataViewEditHead.total_record2 }}
          </b-td>
          <b-td class="text-center">
            {{ passDataViewEditHead.total_amount2 }}
          </b-td>
          <b-td class="text-center">
            {{ passDataViewEditHead.valid_record2 }}
          </b-td>
          <b-td class="text-center">
            {{ passDataViewEditHead.invalid_record2 }}
          </b-td>
          <b-td class="text-center">
            {{ passDataViewEditHead.corrected_record2 }}
          </b-td>
          <b-td class="text-center">
            {{ passDataViewEditHead.tobe_correct_record2 }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple
      caption-top
      bordered
      class="position-relative mb-2"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            colspan="8"
            class="text-center"
            style="background-color: #ffc062;"
          >
            ข้อมูลธนาคารส่งข้อมูล
          </b-th>
          <b-th
            colspan="5"
            class="text-center"
            style="background-color: #00d550;"
          >
            ข้อมูลถูกแปลงแล้ว
          </b-th>
        </b-tr>
      </b-thead>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">
            เลขที่ส่งข้อมูล
          </b-th>
          <b-th class="text-center">
            เวลาส่งข้อมูล
          </b-th>
          <b-th class="text-center">
            วันที่ชำระเงิน
          </b-th>
          <b-th class="text-center">
            ชำระโดย
          </b-th>
          <b-th class="text-center">
            ยอดเงิน
          </b-th>
          <b-th class="text-center">
            รหัสอ้างอิง1
          </b-th>
          <b-th class="text-center">
            รหัสอ้างอิง2
          </b-th>
          <b-th class="text-center">
            ชื่อลูกค้า
          </b-th>
          <b-th class="text-center">
            พอร์ท
          </b-th>
          <b-th class="text-center">
            ชื่อลูกค้า
          </b-th>
          <b-th class="text-center">
            เลขทะเบียน
          </b-th>
          <b-th class="text-center">
            เลขที่สัญญา
          </b-th>
          <b-th class="text-center">
            ประเภทการชำระ
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="passDataView.length">
          <b-tr
            v-for="(item, index) in paginateArray(
              passDataView,
              perPage,
              currentPage
            )"
            :key="index"
            :style="item.is_valid == 'N' ? 'background-color: #F6DDCC;' : null"
          >
            <b-td class="text-center">
              {{ item.request_ref }}
            </b-td>
            <b-td class="text-center">
              {{ item.asdte }}
            </b-td>
            <b-td class="text-center">
              {{ item.geneffdte }}
            </b-td>
            <b-td class="text-center">
              {{ item.termtype_desc }}
            </b-td>
            <b-td class="text-center">
              {{ item.trnpayamt }}
            </b-td>
            <b-td class="text-center">
              {{ item.ref1 }}
            </b-td>
            <b-td class="text-center">
              {{ item.ref2 }}
            </b-td>
            <b-td class="text-center">
              {{ item.name_from_bank }}
            </b-td>
            <b-td class="text-center">
              {{ item.port }}
            </b-td>
            <b-td class="text-center">
              {{ item.customer_name }}
            </b-td>
            <b-td class="text-center">
              {{ item.regno }}
            </b-td>
            <b-td class="text-center">
              {{ item.connum }}
            </b-td>
            <b-td class="text-center">
              {{ item.tran_group }}
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr>
            <b-td
              colspan="11"
              class="text-center"
            >
              <p style="margin-top:5px; margin-bottom:5px;">
                ไม่พบรายการที่ตรงกัน
              </p>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataResult.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd, BRow, BCol, BPagination,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BRow,
    BCol,
    BPagination,
  },
  props: {
    isPopupViewActive: {
      type: Boolean,
      required: true,
    },
    passDataView: {
      type: [Array, Object],
      required: true,
    },
    bankCode: {
      type: String,
      default: null,
    },
    passDataViewEditHead: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // ประกาศตัวแปร Ref
    const refModalView = ref(null)
    // ประกาศตัวแปรสำหรับเก็บค่า
    // const passDataEdit = ref({})
    const perPage = ref(25)
    const currentPage = ref(1)
    const dataResult = ref([])

    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)

    watch(() => props.isPopupViewActive, (first, second) => {
      if (first) {
        refModalView.value.show()
      } else if (second) {
        refModalView.value.hide()
      }
    })

    return {
      // ประกาศตัวแปรสำหรับเก็บค่า
      // passDataEdit,
      // ประกาศตัวแปร Ref
      refModalView,
      /* คำสั่งแบ่งหน้า */
      paginateArray,
      perPage,
      currentPage,
      dataResult,
    }
  },

}
</script>

<style>

</style>
