<template>
  <b-modal
    ref="refModalViewEdit"
    centered
    hide-footer
    size="xl"
    title="สถานะรับชำระ และแก้ไขข้อมูล"
    @hidden="$emit('update:is-popup-view-edit-active', false);$emit('update:pass-data-view-edit', {});$emit('update:pass-data-head', {})"
  >
    <b-table-simple
      caption-top
      bordered
      class="position-relative mb-2"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">
            วันที่
          </b-th>
          <b-th class="text-center">
            สิ้นสุดเวลา
          </b-th>
          <b-th class="text-center">
            จำนวนรายการ
          </b-th>
          <b-th class="text-center">
            ยอดเงิน
          </b-th>
          <b-th class="text-center">
            ถูกต้อง
          </b-th>
          <b-th class="text-center">
            ผิดพลาด
          </b-th>
          <b-th class="text-center">
            แก้ไขแล้ว
          </b-th>
          <b-th class="text-center">
            ต้องแก้ไข
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="text-center">
            {{ passDataHead.btf_date }}
          </b-td>
          <b-td class="text-center">
            {{ passDataHead.btf_end_time }}
          </b-td>
          <b-td class="text-center">
            {{ passDataHead.total_record }}
          </b-td>
          <b-td class="text-center">
            {{ passDataHead.total_amount }}
          </b-td>
          <b-td class="text-center">
            {{ passDataHead.valid_record }}
          </b-td>
          <b-td class="text-center">
            {{ passDataHead.invalid_record }}
          </b-td>
          <b-td class="text-center">
            {{ passDataHead.corrected_record }}
          </b-td>
          <b-td class="text-center">
            {{ passDataHead.tobe_correct_record }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple
      caption-top
      bordered
      class="position-relative mb-2"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">
            เรียกดู
          </b-th>
          <b-th class="text-center">
            แก้ไข
          </b-th>
          <b-th class="text-center">
            พอร์ต
          </b-th>
          <b-th class="text-center">
            จำนวนรายการ
          </b-th>
          <b-th class="text-center">
            ยอดเงิน
          </b-th>
          <b-th class="text-center">
            ถูกต้อง
          </b-th>
          <b-th class="text-center">
            ผิดพลาด
          </b-th>
          <b-th class="text-center">
            แก้ไขแล้ว
          </b-th>
          <b-th class="text-center">
            ต้องแก้ไข
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="passDataViewEdit.length">
          <b-tr
            v-for="(item, index) in paginateArray(
              passDataViewEdit,
              perPage,
              currentPage
            )"
            :key="index"
          >
            <b-td class="text-center">
              <b-button
                variant="gradient-primary"
                class="btn-icon rounded-circle"
                @click="fncViewItem(item)"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-td>
            <b-td class="text-center">
              <b-button
                variant="gradient-primary"
                class="btn-icon rounded-circle"
                @click="fncEditItem(item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-td>
            <b-td class="text-center">
              {{ item.port_desc }}
            </b-td>
            <b-td class="text-center">
              {{ item.total_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ item.total_amount2 }}
            </b-td>
            <b-td class="text-center">
              {{ item.valid_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ item.invalid_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ item.corrected_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ item.tobe_correct_record2 }}
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr>
            <b-td
              colspan="11"
              class="text-center"
            >
              <p style="margin-top:5px; margin-bottom:5px;">
                ไม่พบรายการที่ตรงกัน
              </p>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataResult.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
      <digital-repayment-popup-view
        :is-popup-view-active.sync="isPopupViewActive"
        :pass-data-view.sync="passDataView"
        :bank-code="bankCode"
        :pass-data-view-edit-head="passDataViewEditHead"
      />
      <digital-repayment-popup-edit
        :is-popup-edit-active.sync="isPopupEditActive"
        :pass-data-edit.sync="passDataEdit"
        :pass-data-view-edit-head="passDataViewEditHead"
        :pass-data-head="passDataHead"
        :bank-code="bankCode"
        @click-select-item="fncClickSelectItemEdit"
        @refresh-item="fncEditItem(tempSelectEditItem)"
      />
    </b-row>
  </b-modal>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd, BRow, BCol, BPagination, BButton,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import DigitalRepaymentPopupView from './DigitalRepaymentPopupView.vue'
import DigitalRepaymentPopupEdit from './DigitalRepaymentPopupEdit.vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BRow,
    BCol,
    BPagination,
    BButton,
    DigitalRepaymentPopupView,
    DigitalRepaymentPopupEdit,
  },
  props: {
    isPopupViewEditActive: {
      type: Boolean,
      required: true,
    },
    passDataViewEdit: {
      type: [Array, Object],
      required: true,
    },
    bankCode: {
      type: String,
      default: null,
    },
    passDataHead: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const APP_STORE_MODULE_NAME = 'appDigitalRepayment'

    // ประกาศตัวแปร Ref
    const refModalViewEdit = ref(null)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    const isPopupViewActive = ref(false)
    const isPopupEditActive = ref(false)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)
    const dataResult = ref([])
    const passDataViewEditHead = ref({})
    const accesstime = new Date()
    const passDataView = ref({})
    const passDataEdit = ref({})
    const tempSelectEditItem = ref(null)

    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    const fncViewItem = data => {
      const payload = {
        action: 'LIST',
        events: 'DAILY_PORT',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        process_date: moment(accesstime).format('YYYY-MM-DD'),
        btf_bank_in: props.bankCode, // bankCode.value,
        rundte_in: props.passDataHead.btf_date,
        cpnbrncod_in: data.port_code,
        lotnum_in: null,
        seqnum_in: null,
        connum_in: null,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_BANK_TRANSFER`, payload)
        .then(response => {
          console.log(response)
          passDataView.value = response.data.responseData
          isPopupViewActive.value = true
          passDataViewEditHead.value = data
        })
    }

    const fncEditItem = data => {
      const payload = {
        action: 'LIST',
        events: 'DAILY_PORT_FIX',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        process_date: moment(accesstime).format('YYYY-MM-DD'),
        btf_bank_in: props.bankCode, // bankCode.value,
        rundte_in: props.passDataHead.btf_date,
        cpnbrncod_in: data.port_code,
        lotnum_in: null,
        seqnum_in: null,
        connum_in: null,
      }

      tempSelectEditItem.value = data

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_BANK_TRANSFER`, payload)
        .then(response => {
          console.log(response.data.responseData)
          passDataEdit.value = []
          response.data.responseData.forEach(item => {
            passDataEdit.value.push({
              geneffdte: item.geneffdte,
              name_from_bank: item.name_from_bank,
              ref1: item.ref1,
              ref2: item.ref2,
              trnpayamt: item.trnpayamt,
              request_ref: item.request_ref,
              lotnum: item.lotnum,
              seqnum: item.seqnum,
              is_checked: false,
            })
          })

          isPopupEditActive.value = true
          passDataViewEditHead.value = data
        })
    }

    // ฟังก์ชั่น highlight เมื่อคลิก Edit
    const fncClickSelectItemEdit = index => {
      passDataEdit.value.map(item => {
        // eslint-disable-next-line no-param-reassign
        item.is_checked = false
        return item
      })

      passDataEdit.value[index].is_checked = true
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    watch(() => props.isPopupViewEditActive, (first, second) => {
      if (first) {
        refModalViewEdit.value.show()
      } else if (second) {
        refModalViewEdit.value.hide()
      }
    })

    return {
      // ประกาศตัวแปร Ref
      refModalViewEdit,
      // ประกาศตัวแปร Active / Inactive
      isPopupViewActive,
      isPopupEditActive,
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,
      dataResult,
      passDataView,
      passDataEdit,
      passDataViewEditHead,
      tempSelectEditItem,

      /* คำสั่งแบ่งหน้า */
      paginateArray,

      fncViewItem,
      fncEditItem,
      fncClickSelectItemEdit,
    }
  },
}
</script>

<style>

</style>
