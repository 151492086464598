<template>
  <b-modal
    ref="refModalEdit"
    centered
    hide-footer
    size="xl"
    title="สถานะรับชำระ และแก้ไขข้อมูล"
    @hidden="$emit('update:is-popup-edit-active', false);$emit('update:pass-data-edit', {});$emit('update:pass-data-head', {});$emit('update:pass-data-view-edit-head', {});"
  >
    <digital-repayment-popup-search
      :is-popup-search-active.sync="isPopupSearchActive"
      :temp-text-search="tempTextSearch"
      @select-item="fncSelectSearch"
    />
    <b-card>
      <b-table-simple
        caption-top
        bordered
        class="position-relative mb-2"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="text-center">
              รหัสพอร์ต
            </b-th>
            <b-th class="text-center">
              พอร์ต
            </b-th>
            <b-th class="text-center">
              วันที่
            </b-th>
            <b-th class="text-center">
              สิ้นสุดเวลา
            </b-th>
            <b-th class="text-center">
              จำนวนรายการ
            </b-th>
            <b-th class="text-center">
              ยอดเงิน
            </b-th>
            <b-th class="text-center">
              ถูกต้อง
            </b-th>
            <b-th class="text-center">
              ผิดพลาด
            </b-th>
            <b-th class="text-center">
              แก้ไขแล้ว
            </b-th>
            <b-th class="text-center">
              ต้องแก้ไข
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td class="text-center">
              {{ passDataViewEditHead.port_code }}
            </b-td>
            <b-td class="text-center">
              {{ passDataViewEditHead.port_desc }}
            </b-td>
            <b-td class="text-center">
              {{ passDataHead.btf_date }}
            </b-td>
            <b-td class="text-center">
              {{ passDataHead.btf_end_time }}
            </b-td>
            <b-td class="text-center">
              {{ passDataViewEditHead.total_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ passDataViewEditHead.total_amount2 }}
            </b-td>
            <b-td class="text-center">
              {{ passDataViewEditHead.valid_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ passDataViewEditHead.invalid_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ passDataViewEditHead.corrected_record2 }}
            </b-td>
            <b-td class="text-center">
              {{ passDataViewEditHead.tobe_correct_record2 }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple
        caption-top
        bordered
        class="position-relative mb-2"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="text-center">
              แก้ไข
            </b-th>
            <b-th class="text-center">
              วันที่โอน
            </b-th>
            <b-th class="text-center">
              ชื่อลูกค้า
            </b-th>
            <b-th class="text-center">
              รหัสอ้างอิง1
            </b-th>
            <b-th class="text-center">
              รหัสอ้างอิง2
            </b-th>
            <b-th class="text-center">
              ยอดเงิน
            </b-th>
            <b-th class="text-center">
              เลขที่การโอน
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="passDataEdit.length">
            <b-tr
              v-for="(item, index) in paginateArray(
                passDataEdit,
                perPage,
                currentPage
              )"
              :key="index"
              :style="item.is_checked ? 'background-color: #F6DDCC;' : null"
            >
              <b-td class="text-center">
                <b-button
                  variant="gradient-primary"
                  class="btn-icon rounded-circle"
                  @click="fncSelectItem(index)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </b-td>
              <b-td
                class="text-center"
              >
                {{ item.geneffdte }}
              </b-td>
              <b-td class="text-center">
                {{ item.name_from_bank }}
              </b-td>
              <b-td class="text-center">
                {{ item.ref1 }}
              </b-td>
              <b-td class="text-center">
                {{ item.ref2 }}
              </b-td>
              <b-td class="text-center">
                {{ item.trnpayamt }}
              </b-td>
              <b-td class="text-center">
                {{ item.request_ref }}
              </b-td>
            </b-tr>
          </template>
          <template v-else>
            <b-tr>
              <b-td
                colspan="11"
                class="text-center"
              >
                <p style="margin-top:5px; margin-bottom:5px;">
                  ไม่พบรายการที่ตรงกัน
                </p>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataResult.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="inputTextSearch"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค้นหาข้อมูล <span class="text-danger">*</span>
              </div>
            </template>
            <b-input-group>
              <b-form-input
                v-model="inputTextSearch"
                placeholder="ค้นหาข้อมูล"
                trim
                @keydown.enter.native="
                  fncTextSearch(inputTextSearch)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupSearch()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for=""
            label-cols-md="1"
            label-cols-sm="12"
          >
            <b-button
              variant="success"
              @click="fncEditItem()"
            >
              <feather-icon icon="EditIcon" /> แก้ไขข้อมูล
            </b-button>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for=""
            label-cols-md="1"
            label-cols-sm="12"
          >
            <b-button
              variant="danger"
              @click="fncClearSearch()"
            >
              <feather-icon icon="BinIcon" /> ล้างข้อมูลการค้นหา
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เลขอ้างอิง"
            label-for="referenceNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขอ้างอิง
              </div>
            </template>
            <b-form-input
              id="referenceNum"
              :value="referenceNum"
              placeholder="เลขอ้างอิง"
              trim
              disabled
              style="background-color:#ffdddd;"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="chassisNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขตัวถัง
              </div>
            </template>
            <b-form-input
              id="chassisNum"
              :value="chassisNum"
              placeholder="หมายเลขตัวถัง"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เลขบัตรประชาชน"
            label-for="idNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขบัตรประชาชน
              </div>
            </template>
            <b-form-input
              id="idNum"
              :value="idNum"
              placeholder="เลขบัตรประชาชน"
              trim
              disabled
              style="background-color:#ffdddd;"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่า"
            label-for="nextRental"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่า
              </div>
            </template>
            <b-form-input
              id="nextRental"
              :value="nextRental"
              placeholder="ค่าเช่า"
              trim
              disabled
              style="background-color:#ffdddd;"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เลขทะเบียน"
            label-for="regNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขทะเบียน
              </div>
            </template>
            <b-form-input
              id="regNum"
              :value="regNum"
              placeholder="เลขทะเบียน"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อลูกค้า"
            label-for="customerName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อลูกค้า
              </div>
            </template>
            <b-form-input
              id="customerName"
              :value="customerName"
              placeholder="ชื่อลูกค้า"
              trim
              disabled
              style="background-color:#ffdddd;"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="รหัสพอร์ต"
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                รหัสพอร์ต
              </div>
            </template>
            <b-form-input
              id="branchCode"
              :value="branchCode"
              placeholder="รหัสพอร์ต"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="พอร์ต"
            label-for="branchName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-form-input
              id="branchName"
              :value="branchName"
              placeholder="พอร์ต"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เลขที่สัญญา"
            label-for="conNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขที่สัญญา
              </div>
            </template>
            <b-form-input
              id="conNum"
              :value="conNum"
              placeholder="เลขที่สัญญา"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="สหกรณ์"
            label-for="cooPerate"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สหกรณ์
              </div>
            </template>
            <b-form-input
              id="cooPerate"
              :value="cooPerate"
              placeholder="สหกรณ์"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เซลล์แมน"
            label-for="saleName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เซลล์แมน
              </div>
            </template>
            <b-form-input
              id="saleName"
              :value="saleName"
              placeholder="เซลล์แมน"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd, BRow, BCol, BPagination, BButton, BCard, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import DigitalRepaymentPopupSearch from './DigitalRepaymentPopupSearch.vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BRow,
    BCol,
    BPagination,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    DigitalRepaymentPopupSearch,
  },
  props: {
    isPopupEditActive: {
      type: Boolean,
      required: true,
    },
    passDataEdit: {
      type: [Array, Object],
      required: true,
    },
    bankCode: {
      type: String,
      default: null,
    },
    passDataViewEditHead: {
      type: Object,
      required: true,
    },
    passDataHead: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'appDigitalRepayment'

    // ประกาศตัวแปร Ref
    const refModalEdit = ref(null)
    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)
    const dataResult = ref([])
    const accesstime = new Date()
    const referenceNum = ref(null)
    const chassisNum = ref(null)
    const idNum = ref(null)
    const nextRental = ref(null)
    const regNum = ref(null)
    const customerName = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const conNum = ref(null)
    const cooPerate = ref(null)
    const saleName = ref(null)
    const tempTextSearch = ref(null)

    // ประกาศตัวแปร Textbox
    const inputTextSearch = ref(null)

    /* ฟังก์ชั่นเมื่อมีการคลิกเลือกรายการ */
    const fncSelectSearch = data => {
      console.log('fncSelectBank', data)
      referenceNum.value = data.reference_num
      chassisNum.value = data.chassis_num
      idNum.value = data.id_number
      nextRental.value = data.next_rental
      regNum.value = data.regnum1
      customerName.value = data.customer
      branchCode.value = data.cpnbrncod
      branchName.value = data.cpnbrncod_desc
      conNum.value = data.connum
      cooPerate.value = data.cooperate
      saleName.value = data.sale_name
    }

    /* ฟังก์ชั่นลบรายการ */
    const fncClearSearch = () => {
      referenceNum.value = null
      chassisNum.value = null
      idNum.value = null
      nextRental.value = null
      regNum.value = null
      customerName.value = null
      branchCode.value = null
      branchName.value = null
      conNum.value = null
      cooPerate.value = null
      saleName.value = null
    }

    /* ฟังก์ชั่นยกเลิกธนาคารที่เลือก */
    const fncCanceldata = () => {
      inputTextSearch.value = null
    }

    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    // ประกาศตัวแปร Active / Inactive
    const isPopupSearchActive = ref(false)

    /* ฟังก์ชั่นเปิด Popup Bank */
    const fncOpenPopupSearch = search => {
      // console.log('fncOpenPopupBank')
      if (search) tempTextSearch.value = search
      else tempTextSearch.value = null

      isPopupSearchActive.value = true
    }

    /* ฟังก์ชั่น edit item */
    const fncSelectItem = index => {
      emit('click-select-item', index)
    }

    const fncEditItem = () => {
      const selectItem = props.passDataEdit.filter(item => item.is_checked)
      const payload = {
        action: 'EDIT',
        events: 'DAILY_PORT_FIX',
        function: 'SUBMIT',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        process_date: moment(accesstime).format('YYYY-MM-DD'),
        btf_bank_in: props.bankCode, // bankCode.value,
        rundte_in: props.passDataHead.btf_date,
        cpnbrncod_in: props.passDataViewEditHead.port_code,
        lotnum_in: selectItem[0].lotnum,
        seqnum_in: selectItem[0].seqnum,
        connum_in: conNum.value,
      }

      console.log(payload)

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_BANK_TRANSFER`, payload)
        .then(response => {
          console.log(response)
          fncClearSearch()
          emit('refresh-item')
        })
    }

    /* ฟังก์ชั่นค้นหาเซลล์ */
    const fncTextSearch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: 'RENTAL',
          function: 'GET',
          username: JSON.parse(localStorage.getItem('userData')).member_no,
          acccodmkt_str: null,
          acccodmkt_end: null,
          regnum1_in: null,
          regnum2_in: null,
          TEXT_SEARCH: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_OF_SALE`, payload)
          .then(response => {
            console.log(response)
            if (response.data.responseData.length === 1) {
              fncSelectSearch(response.data.responseData[0])
              inputTextSearch.value = null
            } else if ((response.data.responseData.length > 1)) {
              fncOpenPopupSearch(val)
            }
          })
      }
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    watch(() => props.isPopupEditActive, (first, second) => {
      if (first) {
        refModalEdit.value.show()
      } else if (second) {
        refModalEdit.value.hide()
      }
    })

    return {
      // ประกาศตัวแปร Ref
      refModalEdit,
      /* คำสั่งแบ่งหน้า */
      paginateArray,
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,
      dataResult,
      referenceNum,
      chassisNum,
      idNum,
      nextRental,
      regNum,
      customerName,
      branchCode,
      branchName,
      conNum,
      cooPerate,
      saleName,
      tempTextSearch,
      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncSelectItem,
      fncEditItem,
      /* ฟังก์ชั่นเปิด Popup Bank */
      fncOpenPopupSearch,

      // ประกาศตัวแปร Active / Inactive
      isPopupSearchActive,
      /* ฟังก์ชั่นเมื่อมีการคลิกเลือกรายการ */
      fncSelectSearch,
      inputTextSearch,
      fncCanceldata,
      fncTextSearch,
      fncClearSearch,
    }
  },

}
</script>

<style>

</style>
