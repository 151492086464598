<template>
  <b-col>
    <b-table-simple
      caption-top
      bordered
      class="position-relative mb-2"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">
            เลือก
          </b-th>
          <b-th class="text-center">
            วันที่
          </b-th>
          <b-th class="text-center">
            สิ้นสุดเวลา
          </b-th>
          <b-th class="text-center">
            จำนวนรายการ
          </b-th>
          <b-th class="text-center">
            ยอดเงิน
          </b-th>
          <b-th class="text-center">
            ถูกต้อง
          </b-th>
          <b-th class="text-center">
            ผิดพลาด
          </b-th>
          <b-th class="text-center">
            แก้ไขแล้ว
          </b-th>
          <b-th class="text-center">
            ต้องแก้ไข
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="dataResult.length">
          <b-tr
            v-for="(item, index) in paginateArray(
              dataResult,
              perPage,
              currentPage
            )"
            :key="index"
          >
            <b-td class="text-center">
              <b-button
                variant="gradient-primary"
                class="btn-icon rounded-circle"
                @click="fncViewEditItem(item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-td>
            <b-td class="text-center">
              {{ item.btf_date }}
            </b-td>
            <b-td class="text-center">
              {{ item.btf_end_time }}
            </b-td>
            <b-td class="text-center">
              {{ item.total_record }}
            </b-td>
            <b-td class="text-center">
              {{ item.total_amount }}
            </b-td>
            <b-td class="text-center">
              {{ item.valid_record }}
            </b-td>
            <b-td class="text-center">
              {{ item.invalid_record }}
            </b-td>
            <b-td class="text-center">
              {{ item.corrected_record }}
            </b-td>
            <b-td class="text-center">
              {{ item.tobe_correct_record }}
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr>
            <b-td
              colspan="11"
              class="text-center"
            >
              <p style="margin-top:5px; margin-bottom:5px;">
                ไม่พบรายการที่ตรงกัน
              </p>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataResult.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <digital-repayment-popup-view-edit
      :is-popup-view-edit-active.sync="isPopupViewEditActive"
      :pass-data-view-edit.sync="passDataViewEdit"
      :bank-code="bankCode"
      :pass-data-head.sync="passDataHead"
    />
  </b-col>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd, BRow, BCol, BPagination, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import DigitalRepaymentPopupViewEdit from './digital-repayment-popup/DigitalRepaymentPopupViewEdit.vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BRow,
    BCol,
    BPagination,
    BButton,
    DigitalRepaymentPopupViewEdit,
  },

  props: {
    dataResult: {
      type: [Object, Array],
      required: true,
    },
    bankCode: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const APP_STORE_MODULE_NAME = 'appDigitalRepayment'

    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)
    const passDataViewEdit = ref([])
    const accesstime = new Date()
    const passDataHead = ref({})
    // const btfDate = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupViewEditActive = ref(false)

    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่น edit item */
    const fncViewEditItem = data => {
      const payload = {
        action: 'LIST',
        events: 'DAILY_DATA',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        process_date: moment(accesstime).format('YYYY-MM-DD'),
        btf_bank_in: props.bankCode, // bankCode.value,
        rundte_in: data.btf_date,
        cpnbrncod_in: null,
        lotnum_in: null,
        seqnum_in: null,
        connum_in: null,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_BANK_TRANSFER`, payload)
        .then(response => {
          passDataViewEdit.value = response.data.responseData
          isPopupViewEditActive.value = true
          passDataHead.value = data
        })
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    return {
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,
      passDataViewEdit,
      passDataHead,

      // ประกาศตัวแปร Active / Inactive
      isPopupViewEditActive,

      // ส่วนการทำงานแสดงผล UI
      paginateArray,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncViewEditItem,
    }
  },
}
</script>

<style>

</style>
